import React from 'react';
import { Helmet } from 'react-helmet';

import PageWrapper from '../components/PageWrapper';
import Hero from '../sections/about/Hero';
import Masonry from '../sections/about/Masonry';
import Content from '../sections/about/Content';
// import Team from "../sections/about/Team";
// import Stats from "../sections/about/Stats";
import HeaderButton from '../components/HeaderButton';

const AboutPage = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: 'site-header--menu-right',
          headerButton: <HeaderButton />,
          footerStyle: 'style3',
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>UStrive | About</title>
          <link rel="canonical" href="https://ustrive.com/about" />
          <meta
            name="description"
            content="UStrive was started in 2015 as a way to virtually connect students to qualified mentors in a safe and secure environment."
          />
        </Helmet>
        <Hero />
        <Masonry />
        <Content />
        {/* <Team /> */}
        {/* <Stats /> */}
      </PageWrapper>
    </>
  );
};
export default AboutPage;
